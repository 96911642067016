import { keyframes } from '@emotion/react';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const slideUp = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
      transform: translate3d(0,0,0);
  }
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
`;

export const horizontalShaking = keyframes`
  0% { transform: translateX(0) }
  33% { transform: translateX(-10px) }
  66% { transform: translateX(10px) }
  100% { transform: translateX(0) }
`;

export const fadeInToValue = (value: number) => keyframes`
  from { opacity: 0; }
  to { opacity: ${value}; }
`;

export const fadeOutToValue = (value: number) => keyframes`
  from { opacity: ${value}; }
  to { opacity: 0; }
`;

export const slideFromLeft = keyframes`
  from { transform: translateX(-100%); }
  to { transform: none; }
`;

export const slideFromBottom = keyframes`
  from { transform: translateY(100%); }
  to { transform: none; }
`;

export const slideFromRight = keyframes`
  from { transform: translateX(100%); }
  to { transform: none; }
`;

export const slideFromTop = keyframes`
  from { transform: translateY(-100%); }
  to { transform: none; }
`;

export const slideFromLeftClosing = keyframes`
  from { transform: none; }
  to { transform: translateX(-100%); }
`;

export const slideFromBottomClosing = keyframes`
  from { transform: none; }
  to { transform: translateY(100%); }
`;

export const slideFromRightClosing = keyframes`
  from { transform: none; }
  to { transform: translateX(100%); }
`;

export const slideFromTopClosing = keyframes`
  from { transform: none; }
  to { transform: translateY(-100%); }
`;
